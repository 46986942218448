






import Vue from 'vue';
import ImageViewer from '~/components/common/ImageViewer.vue';

export default Vue.extend({
  name: 'ImageDialog',
  components: {ImageViewer},
  props: {
    value: {type: Boolean, default: false},
    src: {type: String, default: ''},
    text: {type: String, default: ''},
  },
  computed: {
    model: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
  },
});
