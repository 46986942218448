




















































































import moment from 'moment';
import Vue from 'vue';
import ImageDialog from '~/components/common/ImageDialog.vue';
import {Answer, Figure, Question, TestInstance} from '~/models';

export default Vue.extend({
  components: {ImageDialog},
  name: 'Test',
  data() {
    return {
      img: {dialog: false, src: null},
      elapsedTime: '',
      showConfirmDialog: false,
      intervalId: null,
    };
  },
  mounted() {
    this.updateElapsedTime();
    this.intervalId = setInterval(() => this.updateElapsedTime(), 1000);
    window.addEventListener('keyup', this.handleKey);
  },
  destroyed(): void {
    clearInterval(this.intervalId);
    window.removeEventListener('keyup', this.handleKey);
  },
  computed: {
    questionIndex(): number {
      return this.$store.state.test.questionIndex + 1;
    },
    testInstance(): TestInstance {
      return this.$store.state.test.testInstance;
    },
    answerAmount(): number {
      return Object.keys(this.$store.state.test.answers).length;
    },
    question(): Question {
      return this.$store.getters['test/question'];
    },
    timeLimit(): string {
      return moment.utc(this.testInstance.timeLimit * 60 * 1000).format('HH:mm:ss');
    },
    allQuestionsAnswered(): boolean {
      return this.answerAmount === this.testInstance.questions.length;
    },
  },
  methods: {
    async onSubmitClick() {
      if (!this.allQuestionsAnswered) {
        this.showConfirmDialog = true;
      } else {
        await this.submit();
      }
    },
    async confirmSubmit() {
      await this.submit();
      this.showConfirmDialog = false;
    },
    async submit(autoSubmit = false) {
      try {
        if (autoSubmit) {
          await this.$store.dispatch('test/autoSubmit');
        } else {
          await this.$store.dispatch('test/submit');
        }
      } catch (e) {
        console.log(e);
      }

      if (this.testInstance.isPreExam) {
        await this.$router.push({name: 'test-details', params: {id: String(this.testInstance.testId)}});
        await this.$store.dispatch('snackbar/showSnack', {msg: this.$t('msg.preExamSubmitted'), color: 'info', persistent: true});
      } else {
        if (this.testInstance.isFinal) {
          await this.$store.dispatch('testInstance/showFinalTestDialog', true);
        }
        await this.$router.push({name: 'test-results', params: {id: String(this.testInstance.id)}});
      }
      await this.$store.dispatch('test/clearTest');
    },
    updateElapsedTime() {
      if (this.$store.state.test.startTime) {
        const diff = moment().diff(this.$store.state.test.startTime);

        if (this.testInstance.timeLimit) {
          const diffInMinutes = diff / (1000 * 60);
          if (diffInMinutes >= this.testInstance.timeLimit) {
            this.submit(true);
          }
        }

        if (diff < 0) {
          this.elapsedTime = moment.utc(0).format('HH:mm:ss');
        } else {
          this.elapsedTime = moment.utc(diff).format('HH:mm:ss');
        }
      }
    },
    showFigure(figure: Figure) {
      this.img.src = figure.path;
      this.img.dialog = true;
    },
    isSelected(answer: Answer) {
      return this.$store.state.test.answers[this.question.id] === answer.id;
    },
    handleKey(e: KeyboardEvent) {
      const currentAnswerId = this.$store.state.test.answers[this.question.id];
      const currentAnswerIndex = this.question.answers.findIndex(a => a.id === currentAnswerId);

      switch (e.key) {
        case 'ArrowLeft':
          if (this.questionIndex > 1) {
            this.img.dialog = false;
            return this.$store.dispatch('test/prevQuestion');
          }
          break;
        case 'ArrowRight':
          if (this.questionIndex < this.testInstance.questions.length) {
            this.img.dialog = false;
            return this.$store.dispatch('test/nextQuestion');
          }
          break;
        case 'ArrowUp':
          if (currentAnswerIndex > 0) {
            this.$store.dispatch('test/setAnswer', this.question.answers[currentAnswerIndex - 1]);
          }
          break;
        case 'ArrowDown':
          if (currentAnswerIndex < this.question.answers.length - 1) {
            this.$store.dispatch('test/setAnswer', this.question.answers[currentAnswerIndex + 1]);
          }
          break;
      }
    },
    getIcon(index: number) {
      switch (index) {
        case 0:
          return 'mdi-alpha-a-circle';
        case 1:
          return 'mdi-alpha-b-circle';
        case 2:
          return 'mdi-alpha-c-circle';
        case 3:
          return 'mdi-alpha-d-circle';
      }
    },
  },
});
